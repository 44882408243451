import { ProductData } from "@/lib/5874/types";
const mapProductOptions = (productOptions: any) => {
	const options: {
		order: number;
		label: string;
		options: { label: string; value: string }[];
	}[] = [];

	for (const product of productOptions) {
		if (!product || !product.customFields) continue;

		for (const customField of product.customFields) {
			if (customField.name.startsWith("O")) {
				const key = customField.name.split("|");

				// check to see if we have already added the option
				const existingOption = options.find(
					(option) => option.label === key[1],
				);

				if (existingOption) {
					// Check to make sure we haven't already added this option
					const existingValue = existingOption.options.find(
						(option) => option.label === customField.value,
					);

					if (existingValue) continue;

					existingOption.options.push({
						label: customField.value,
						value: product.path,
					});
				} else {
					const newOption = {
						order: parseInt(key[0].replace("O", "")),
						label: key[1],
						options: [
							{
								label: customField.value,
								value: product.path,
							},
						],
					};

					options.push(newOption);
				}
			}
		}
	}

	return options;
};

export default mapProductOptions;
