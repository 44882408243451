import {
	Client,
	ClientConfig,
	ClientGlobals,
	RecommendCombinedResponseModel,
} from "@searchspring/snap-client";
import {
	MetaResponseModel,
	SearchRequestModel,
} from "@searchspring/snapi-types";
import { redirect } from "next/navigation";
import {
	getProductsByIds,
	getProductsWithoutChildrenByIds,
} from "../bigcommerce/product";
import { getConfig } from "./searchspring.config";
import {
	EnrichedRecommendCombinedRequestModel,
	EnrichedRecommendCombinedResponseModel,
	EnrichedSearchRequestModel,
} from "./types";
import { StoreRegion } from "../builder/builder.config";
import { IntelliSuggestItem, ProductData } from "../5874/types";
import { indexOf, orderBy } from "lodash";

export class SearchSpringClient extends Client {
	private store;
	private lang;

	siteId = "";

	constructor(
		store: StoreRegion,
		lang: string,
		globals: ClientGlobals,
		clientConfig?: ClientConfig,
	) {
		super(globals, clientConfig);

		this.store = store;
		this.lang = lang;
		this.siteId = globals.siteId;
	}

	/**
	 * Performs a search with enriched results.
	 *
	 * @param {SearchRequestModel} [params] - Optional search parameters.
	 * @return {Promise<[MetaResponseModel, EnrichedSearchRequestModel]>} A promise that resolves to an array containing the meta response model and the enriched search request model.
	 */
	async enrichedSearch(
		params?: SearchRequestModel,
	): Promise<[MetaResponseModel, EnrichedSearchRequestModel]> {
		const [meta, results] = await super.search(params);

		if (results.merchandising?.redirect) {
			redirect(`/${this.store}/${this.lang}${results.merchandising.redirect}`);
		}

		if (!results.results) {
			return [
				meta,
				{
					...(results as any),
					results: [],
				},
			];
		}

		const productEntityIds = results?.results
			?.map((result) => {
				const id = result.mappings?.core?.uid;

				return id ? parseInt(id) : undefined;
			})
			.filter((value) => value !== undefined);

		const products: ProductData[] = await getProductsByIds(
			productEntityIds as any,
		);

		const sortedResults: ProductData[] = orderBy(products, (product) => {
			return indexOf(productEntityIds, product.id);
		});

		return [meta, { ...(results as any), results: sortedResults }];
	}
	async enrichedRecommend(
		params: EnrichedRecommendCombinedRequestModel,
	): Promise<EnrichedRecommendCombinedResponseModel> {
		const { meta, profile, results } = await super.recommend(params);

		if (!results) {
			return {
				meta,
				profile,
				results: [],
			};
		}

		const productEntityIds = results
			.map((result) => {
				const id = result.mappings?.core?.uid;

				return id ? parseInt(id) : undefined;
			})
			.filter((value) => value !== undefined);

		const products = await getProductsWithoutChildrenByIds(
			productEntityIds as any,
		);

		return {
			meta,
			profile,
			results: products!,
		};
	}

	async getSearchSuggestions(searchTerm: string): Promise<any> {
		const siteId = getConfig(this.store).globals.siteId;

		try {
			const response = await fetch(
				`https://${siteId}.a.searchspring.io/api/suggest/query?siteId=${siteId}&q=${encodeURIComponent(
					searchTerm,
				)}&limit=4`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				},
			);

			if (!response.ok) {
				throw new Error(
					`Error fetching search suggestions: ${response.statusText}`,
				);
			}

			const results = await response.json();

			const suggestions = [];
			if (results?.suggested?.text) suggestions.push(results?.suggested?.text);

			for (const alternatives of results?.alternatives as any[]) {
				suggestions.push(alternatives.text);
			}

			return suggestions;
		} catch (error) {
			console.error("Error fetching search suggestions:", error);
			throw error;
		}
	}

	async getSearchAutoCompleteProducts(searchTerm: string): Promise<any> {
		const siteId = getConfig(this.store).globals.siteId;

		try {
			const response = await fetch(
				`https://${siteId}.a.searchspring.io/api/search/autocomplete.json?siteId=${siteId}&q=${encodeURIComponent(
					searchTerm,
				)}&resultsFormat=json&limit=10`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				},
			);

			if (!response.ok) {
				throw new Error(
					`Error fetching search products: ${response.statusText}`,
				);
			}

			const results = await response.json();

			const productEntityIds = results?.results?.map(
				(result: { uid: string }) => {
					const id = result.uid;

					return id ? parseInt(id) : undefined;
				},
			);

			const products = await getProductsByIds(productEntityIds);

			return products;
		} catch (error) {
			console.error("Error fetching search products:", error);
			throw error;
		}
	}

	async getSearchAutoCompleteResults(
		searchTerm: string,
	): Promise<IntelliSuggestItem[]> {
		const siteId = getConfig(this.store).globals.siteId;

		try {
			const response = await fetch(
				`https://${siteId}.a.searchspring.io/api/search/autocomplete.json?siteId=${siteId}&q=${encodeURIComponent(
					searchTerm,
				)}&resultsFormat=json&limit=11`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				},
			);

			if (!response.ok) {
				throw new Error(
					`Error fetching search products: ${response.statusText}`,
				);
			}

			const results = await response.json();

			return results.results;
		} catch (error) {
			console.error("Error fetching search products:", error);
			throw error;
		}
	}

	async getSearchTrendingSuggestions(): Promise<any> {
		const siteId = getConfig(this.store).globals.siteId;

		try {
			const response = await fetch(
				`https://${siteId}.a.searchspring.io/api/suggest/trending?siteId=${siteId}&limit=4`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				},
			);

			if (!response.ok) {
				throw new Error(
					`Error fetching trending search suggestions: ${response.statusText}`,
				);
			}

			const results = await response.json();

			const productEntityIds = results?.results?.map(
				(result: { uid: string }) => {
					const id = result.uid;

					return id ? parseInt(id) : undefined;
				},
			);

			const products = await getProductsByIds(productEntityIds);

			return products;
		} catch (error) {
			console.error("Error fetching trending search suggestions:", error);
			throw error;
		}
	}
}

export const getClient = async (store: StoreRegion, lang: string) => {
	const configs = getConfig(store);
	const client = new Client(configs.globals, configs.clientConfig);
	return client;
};

export const getSearchSpringClient = (store: StoreRegion, lang: string) => {
	const configs = getConfig(store);

	const client = new SearchSpringClient(
		store,
		lang,
		configs.globals,
		configs.clientConfig,
	);

	return client;
};

export const fetchProductsFromIds = async (results: any) => {
	const productEntityIds = results?.results?.map((result: { uid: string }) => {
		const id = result.uid;

		return id ? parseInt(id) : undefined;
	});

	const products = await getProductsByIds(productEntityIds);

	return products;
};

export function removeSubstring(input: string, substring: string): string {
	return input.replace(substring, "");
}
